import { useNavigate } from "react-router-dom";
import ServiceBox from "./ServiceBox";

const services = [
  {
    title:
      "строительно техническая экспертиза определение причины залития помещений",
    image: "services/zaliv.svg",
    description:
      "Услуга определения причины залития помещений включает в себя комплекс мероприятий, направленных на выявление источника утечки или нарушения герметичности, приведших к залитию. Специалисты проводят тщательное обследование поврежденного объекта с использованием современных диагностических инструментов и методов. В результате определяется причина залития, будь то неисправность сантехнического оборудования, нарушение целостности кровли или стен, неправильный монтаж инженерных систем и прочее.",
  },
  {
    title: "обследование канализации",
    image: "services/drain.png",
    description:
      "Обследование канализации включает в себя проверку состояния трубопроводов, колодцев и других элементов системы, чтобы выявить возможные проблемы, такие как засоры, трещины, коррозию или повреждения. Обычно это проводится с помощью специальных инструментов, таких как видеокамеры, которые позволяют осмотреть внутреннюю поверхность труб, а также с помощью гидродинамических и механических методов для очистки и восстановления функциональности системы. Регулярное обследование позволяет предотвратить серьезные сбои и поддерживать эффективность канализационной системы.",
  },
  {
    title: "экспертиза признание дома жилым для круглогодичного проживания",
    image: "services/house.png",
    description:
      "Экспертиза признания дома жилым для круглогодичного проживания обычно включает в себя проверку его соответствия строительным нормам и санитарным требованиям, таких как наличие надлежащего отопления, водоснабжения, канализации и вентиляции. Основные критерии включают обеспечение стабильного температурного режима, достаточного уровня освещенности, а также отсутствие вредных факторов, таких как сырость или плесень, что позволяет дому быть пригодным для комфортного проживания в течение всего года.",
  },
  {
    title: "определение  качества выполненных ремонтных работ по отделке",
    image: "services/houseQuality.png",
    description:
      "Качество выполненных ремонтных работ по отделке оценивается по нескольким критериям, включая аккуратность выполнения, соответствие проектной документации и требованиям заказчика, а также долговечность и функциональность отделочных материалов. Важными аспектами являются ровность поверхностей, отсутствие дефектов, таких как трещины или неровности, а также правильное сочетание цвета и текстуры материалов. Также учитываются сроки выполнения работ и соблюдение стандартов безопасности и экологических норм.",
  },
  {
    title: "тепловизионная экспертиза определение утечек тепла",
    image: "services/heat.png",
    description:
      "Тепловизионная экспертиза – это метод обследования зданий и сооружений с использованием тепловизора для выявления утечек тепла. Этот неинвазивный способ диагностики позволяет точно определить зоны теплопотерь, которые могут быть вызваны дефектами в теплоизоляции, неисправностью окон или дверей, нарушением герметичности конструкций и другими факторами. Процесс включает в себя сканирование поверхностей здания с помощью тепловизора, который фиксирует тепловые аномалии и визуализирует их в виде тепловых карт. Результаты экспертизы позволяют выявить проблемные участки и принять меры по их устранению, что способствует повышению энергоэффективности здания и снижению затрат на отопление Тепловизионная экспертиза особенно полезна в холодное время года, когда разница температур между внутренними и наружными помещениями наиболее заметна, что делает утечки тепла легко обнаруживаемыми.",
  },
  {
    title: "определение качества строительно монтажных работ",
    image: "services/quality.png",
    description:
      "Качество строительно-монтажных работ определяется как степень соответствия выполненных работ установленным проектным требованиям, нормативам и стандартам. Это включает в себя точность выполнения проектных решений, использование материалов и технологий, соответствующих установленным стандартам, а также соблюдение сроков и условий эксплуатации. Высокое качество строительно-монтажных работ обеспечивает надежность, долговечность и безопасность сооружений и конструкций, что напрямую влияет на их функциональные характеристики и эксплуатационные свойства.",
  },
  {
    title: "лабораторные исследования оборудования",
    image: "services/lab.png",
    description:
      "Лабораторные исследования запорных устройств, шлангов и фитингов являются ключевыми для обеспечения их надежности и безопасности. Эти устройства, включая клапаны и соединения, подвергаются нагрузкам, поэтому важно проверять их герметичность, прочность и материалы на дефекты. Металлографические исследования изучают микроструктуру материалов для выявления трещин и коррозии, а фрактографический анализ помогает определить характер разрушений. Визуально-инструментальный контроль с использованием луп и микроскопов позволяет обнаружить как видимые, так и скрытые дефекты, что помогает выявить причины залитий и других неисправностей. Эти методы способствуют своевременному ремонту и предотвращают аварии, обеспечивая надежную работу оборудования.",
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleServiceClick = (service) => {
    navigate("/services", { state: { service: service } });
    window.scrollTo(0, 0);
  };

  return (
    <div id="services">
      <div className="jobsTitle">Услуги</div>
      <div className="jobs">
        <div className="jobsContainer">
          {services.map((service, index) => (
            <div
              key={index}
              onClick={() => handleServiceClick(service)}
              style={{ cursor: "pointer" }}
            >
              <ServiceBox
                title={service.title}
                image={service.image}
                isLongTitle={service.title.length > 100}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
